import Button from 'components/Button/Button';
import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsGenesis() {
  return (
    <div className="container">
      <h3>1. About these Terms of Sale and the contract between the Buyer and the Seller</h3>
      <p>
        1.1 These Terms of Sale apply to the sale and purchase of Mittaria Genesis Collection. They
        also specify the restrictions to the Artwork (as defined in the Mittaria Genesis Collection
        Terms and Conditions). A legally binding agreement incorporating these Terms of Sale will
        come into existence between the creator (in the primary sale/mint) or seller of the Mittaria
        Genesis Collection (in the secondary sale), collectively known as (&ldquo;Seller&rdquo;) and the
        minter/buyer of the Mittaria Genesis Collection (Buyer) when the Buyer&rsquo;s offer is accepted
        by the Seller, as determined or confirmed by the website (Website) where the sale is
        concluded (Contract for Sale). No other person will be a party to the Contract for Sale
        between the Seller and the Buyer.
      </p>

      <h3>2. No right to cancel</h3>

      <p>
        Due to the irreversible nature of all blockchain transactions and the fact that the
        purchase and delivery of a Mittaria Genesis Collection takes place immediately and is
        irreversible, the Buyer consents to immediate performance of the Contract for Sale and
        acknowledges that the Buyer will lose their right of withdrawal from the Contract for Sale
        when they click the button to mint and purchase a Mittaria Genesis Collection, at which
        point the Artwork will be embedded in the Mittaria Genesis Collection and recorded on a
        blockchain. This means that the Buyer will not have the right to cancel their purchase and
        receive a refund. This does not affect any rights the Buyer may have under any applicable
        laws, where such rights cannot be legally excluded.
      </p>

      <h3>3. Payment and the delivery of the Mittaria Genesis Collection</h3>

      <p>
        3.1 As a pre-condition to the purchase of the Mittaria Genesis Collection, the Buyer must:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) make a payment in full in any nominated digital currency or via any other nominated
            payment method (as specified on the Website) to the wallet address nominated by the
            Seller, and in the amount stated on the Website at the time of the acceptance of the
            Buyer&apos;s offer by the Seller (Purchase Price); and
          </p>
        </li>
        <li>
          <p>
            (b) provide the Seller with its digital wallet address by linking its wallet with the
            Website or by any other means specified by the Website. The Buyer shall be solely
            responsible for ensuring that the wallet address provided is correct and the Buyer
            agrees that the Seller will not be liable for any loss or damage caused as a result of
            an incorrect wallet address being provided by the Buyer.
          </p>
        </li>
      </ol>

      <p>
        3.2 The Seller will transfer the Mittaria Genesis Collection to the wallet address provided
        by the Buyer immediately upon acceptance of the Buyer&apos;s offer to purchase the Mittaria
        Genesis Collection and receipt of the Purchase Price from the Seller.
      </p>

      <p>
        3.3 In the event the payment of the Purchase Price is reversed or becomes invalid (for any
        reason), the Buyer shall immediately return the Mittaria Genesis Collection to the Seller by
        transferring it back to the wallet address of the Seller.
      </p>

      <h3>4. Right to use the NFT</h3>

      <p>
        4.1 All rights of the Buyer to the Mittaria Genesis Collection s will terminate immediately
        upon the transfer of the Mittaria Genesis Collection by the Buyer to a third party, subject
        to the contract for sale and any other applicable terms.
      </p>

      <p>
        4.2.The Buyer agrees that in the case of any onward sale of the Mittaria Genesis Collection,
        the grant of rights to the Mittaria Genesis Collection s must be on the same terms as set
        out in these Terms of Sale and including the restrictions specified in clause 5 of this NFT
        Terms of Sale. Any sale of the Mittaria Genesis Collection which purports to modify the
        terms of the grant of rights or the restrictions on use of Mittaria Genesis Collection s
        will result in the automatic termination of the grant of rights to the Mittaria Genesis
        Collection s.
      </p>
      <h3>5. Restrictions on the use of the Asset</h3>

      <p>
        5.1 The Buyer shall not, and shall not permit any other person to do or attempt to do any of
        the following:
      </p>
      <ol className='ml-5' type="a">
        <li>
          <p>
            (a) trademark, copyright, patent or otherwise acquire intellectual property rights in or
            to the Mittaria Genesis Collection or Artwork unless obtained lawfully from the owner of
            such intellectual property rights;
          </p>
        </li>
        <li>
          <p>
            (b) take any action which would or might invalidate, challenge, oppose or otherwise put
            in dispute our intellectual property rights, the owner&apos;s intellectual property rights in
            the Artwork, or contravene the moral rights of the creator of the Artwork;
          </p>
        </li>
        <li>
          <p>
            (c) use the Mittaria Genesis Collection s or the Artwork in connection with any illegal
            activities;
          </p>
        </li>
        <li>
          <p>
            (d) create any security, capital market products, financial instrument or derivative
            product based on the Mittaria Genesis Collection; or the Artwork
          </p>
        </li>
        <li>
          <p>
            (e) use the Mittaria Genesis Collection s or Artwork in connection with any images,
            videos, or any other forms of media or content that depict hatred, intolerance,
            involving the monarchy that is likely to violate lèse-majestée, politically-charged
            messages, violence, cruelty or anything else that could reasonably be found to
            constitute hate speech, infringe upon the rights of others, violating laws, public
            order, or good morals or otherwise bring the Seller into disrepute.
          </p>
        </li>
      </ol>
      <p>
        5.2 Any breach of the restrictions set out in clause 5.1 will result in the automatic
        termination of the right to use the Mittaria Genesis Collection s under clause 4.1.
      </p>

      <p>
        5.3 Nothing in these Terms of Sale grants any licence or right to use the Seller&apos;s,
        intellectual property rights, including but not limited to trademarks or branding.
      </p>

      <h3>6. No partnership or agency.</h3>
      <p>
        6.1 Nothing in these terms is intended to, or shall be deemed to, establish any partnership
        or joint venture between you and us, constitute any party the agent of another party, or
        authorise any party to make or enter into any commitments for or on behalf of any other
        party.
      </p>

      <h3>7. Assignment.</h3>

      <p>
        We may assign our rights and obligations under these terms (without your prior express
        consent), provided that your rights under these terms remain unaffected. You must not assign
        your rights or obligations under these terms.
      </p>

      <h3>8. Events outside control.</h3>

      <p>
        Neither party will have any liability under or be deemed to be in breach of these terms
        for any delays or failures in performance of these terms which result from circumstances
        beyond its reasonable control.
      </p>

      <h3>
        9. Severance, Governing law, Dispute Resolution and Arbitration, and Class Action Waiver
      </h3>

      <p>
        Clause 11.7, Severance, Clause 11.8 Governing Law, Clause 11.9 Dispute Resolution and
        Arbitration, and Clause 11.10 Class Action Waiver of the Mittaria Genesis Collection Terms
        and Conditions shall apply, with the necessary changes being made to these NFTs Terms of
        Sale.
      </p>

      <div className="mt-20 mb-20 flex justify-center">
        <Link to="/creator-form" target="_blank">
          <Button>
            <span className="font-monsterOfFantasy font-normal">Creator Registration</span>
          </Button>
        </Link>
      </div>

      <h2 className="text-center">Terms of Use</h2>

      <p>
        These Terms of Use serves as a guideline for users participating in the Mittamita Project. It is a Project in which the Company issues free NFTs, allowing users to utilize them for product development or keep them as collectibles.
      </p>

      <p>
        PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS.
      </p>

      <h3>1. General Information</h3>
      <p>1.1 These Terms of Use are made between Mittaria Origin Limited, whose registered number is 2100690, and registered address is 1st Floor, Irvine&rsquo;s Place, 159 Main Street, P.O. Box 2132 Road Town, Tortola, VIRGIN ISLANDS, BRITISH (the &ldquo;Company&rdquo;); and the User (The Company and the User collectively referred to as &ldquo;both parties.&rdquo;)</p>
      <p>1.2 The Company provides the Mittamita Project with its objective to issue NFTs named &ldquo;Lo&rdquo; to users and enable users to use NFTs for both personal and commercial purposes, including selling them on leading platforms such as OpenSea, Blur.io, LooksRare, X2Y2 and Magic Eden. On these leading platforms, users can make payments using Ethereum.</p>
      <p>1.3 Users may also use NFTs issued by the Company for product development or keep them as collectibles. </p>
      <p>1.4 The issuance of NFTs in this Project also aims to serve as a promotional tool for the Project. It helps increase awareness and accessibility while enhancing the value of the NFTs through continuous usage and development.</p>

      <h3>2. Conditions before Attending our Project</h3>
      <p>2.1 You confirm that you accept and agree to comply with these Terms of Use. </p>
      <p>2.2 The Company may amend these Terms of Use from time to time, at our sole discretion, without prior notification to users. Every time you wish to use our platform, please check these Terms of Use to ensure you understand the terms that apply at that time.</p>
      <p>2.3 Our Project is committed to providing services to users of all ages. However, in case you are a minor, you hereby represent and warrant that you have obtained parental approval prior to engaging in any financial transactions. Minors may be restricted, prohibited, refused from conducting, or have any transactions cancelled if: (1) the requested transaction is not permitted by applicable law, or (2) due to the conditions set by us.</p>
      <p>2.4 You must not attend our Project if any applicable laws, including but not limited to anti-money laundering laws, counter-terrorist financing laws, anti-corruption laws or economic sanctions laws, would be breached or if such applicable laws prohibit, penalize, sanction, or expose the platform to liability.</p>
      <p>2.5 We reserve the right to prohibit any and all participation of the Project to users domiciled or located in a country or territory we deem (in our sole and absolute discretion) as prohibited, restricting, or subjecting to regulatory requirements, any cryptographic token, virtual asset related activities, including but not limited to Algeria, Bangladesh, China, Egypt, Iran, Iraq, Morocco, Nepal, North Korea, Qatar, Singapore, Tunisia, the United States of America. The Project shall not be offered to any person resident in the British Virgin Islands. The Company does not intend to conduct any cryptographic token, virtual asset, or related activities which may be prohibited or subject to regulatory requirements in those jurisdictions. You represent and warrant that you are not domiciled or a resident in any of the aforementioned jurisdictions.</p>

      <p>In case you encounter any difficulties while using our service, we encourage you to report these issues to us at contact@mittaria.io </p>

      <h3>3. Main Features of our Project</h3>
      <p>3.1 The Company issues 605 free NFTs (&ldquo;Lo&rdquo;). The number of the free NFTs issued by the Company is subject to change at the Company&apos;s discretion. Holders of the Mittaria NFT will be entitled to receive free NFTs, based on specific requirements which would be announced on the Platform later; however, those eligible to receive the NFTs will be required to pay the associated gas fees.</p>
      <p>3.2 Holders of NFTs have full rights to create derivative works based on the NFTs they received from the Company. Holders have the right to use their NFTs to create new works in any form, whether for artistic creation or commercial use. Holders have the right to sell or transfer ownership of the NFTs or its derivative works to others. The ownership of such NFTs will terminate once ownership is transferred to another party. In this regard, the Company will not be involved in or responsible for any transactions or agreements between the NFTs holder and the buyer.</p>

      <h3>4. Intellectual Property Rights</h3>
      <p className="ml-5 font-semibold">4.1 Intellectual property rights in the material published and products displayed on our Platform</p>
      <ol className='ml-5' type="a">
        <li>
          <p>The Company and/or its licensors own the intellectual property rights including copyrights, trademarks, patents, and trade secrets, know-how, on Platform and all material published on the Platform. Those works are protected by intellectual property laws and international treaties globally. This means that you are not permitted to copy or use any such content other than in accordance with this clause. All such rights are reserved. You may print off one copy and may download extracts of any page(s) from our platform for your personal use only. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Our status (and that of any identified contributors) as the authors of content on our Platform must always be recognized and respected.</p>
        </li>
      </ol>
      <p className="ml-5">4.2 No commercial use of our content </p>
      <ol className='ml-5' type="a">
        <li>
          <p>Content on our Platform is strictly for non-commercial use unless a license has been granted by us for such purposes.</p>
        </li>
      </ol>
      <p className="ml-5">4.3 No data mining or web scraping </p>
      <ol className='ml-5' type="a">
        <li>
          <p>Users shall not conduct, facilitate, authorise, or permit any text or data mining or web scraping in relation to our Platform. This includes using (or permitting, authorising or attempting the use of): (1) any &ldquo;robot&rdquo;, &ldquo;bot&rdquo;, &ldquo;spider&rdquo;, &ldquo;scraper&rdquo; or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same; and (2) any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</p>
        </li>
      </ol>
      <p className="ml-5">4.4 Content on Platform </p>
      <ol className='ml-5' type="a">
        <li>
          <p>The content on our Platform is provided for general information only. Although we make reasonable efforts to update the information on our Platform, we make no representations, warranties or guarantees, whether express or implied, that the content on our Platform is accurate, complete, or up to date.</p>
        </li>
      </ol>
      <p className="ml-5">4.5 Third-party sites, products and services </p>
      <ol className='ml-5' type="a">
        <li>
          <p>Where our Platform may contain links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources. Any interaction or dispute you may subsequently have with those third parties is subject to those third parties&apos; terms only.</p>
        </li>
      </ol>

      <h3>5. Rights of NFTs Holders</h3>
      <p>5.1 Holders of NFTs shall have full rights to create derivative works from the NFTs received from the Company. However, intellectual property of the original works (&ldquo;Lo&rdquo;) and derivative works belongs to the Company. Creators have the right to use NFTs for both personal purposes, such as displaying them as profile pictures on social media, using them as avatars or skins in digital worlds, or showcasing the NFTs in private spaces or other activities, as well as for commercial purposes. Commercial rights include the sale or transfer of NFTs to others in marketplaces, generating revenue from NFTs through various means such as renting, further developing them into other products, or engaging in any other commercial activities. In this regard, it does not restrict the Company&rsquo;s rights to further utilize the derivative works for commercial purposes. </p>
      <p>5.2 Holders of NFTs may exploit the NFTs they hold for both personal and commercial purposes without the need for prior approval from the Company, unless the NFT is to be developed into a keychain or a game. If NFTs holders wish to use their NFTs for producing a keychain or a game, they must obtain written permission from the Company before proceeding with development and distribution. In cases where prior written consent from the company has not been obtained, if the Company detects a violation, it has the right to order the cessation of distribution or publication, as the case may be. This does not affect the Company&apos;s right to claim damages, including all litigation expenses, from the respective user.</p>

      <h3>6. Creation and Trading of NFTs </h3>
      <p>6.1 Creations or derivative works must not contain violent, obscene, or inappropriate content, and must not involve copying, modifying, or imitating the works of others. Additionally, they must not infringe on any intellectual property laws. If the Company identifies a creation that meets these criteria, the Company reserves the right to order the removal or destruction of any derivative works of the NFTs and has the right to order the cessation of distribution or publication, as the case may be. This does not affect the Company&apos;s right to claim damages, including all litigation expenses, from the respective user.</p>
      <p>6.2 NFTs holders have full rights to sell, rent, or transfer their NFTs rights, with terms, conditions and pricing to be mutually agreed upon between the NFTs holder and the buyer. The Company is not involved in, nor responsible for, any transactions between the NFTs holder and the buyer. Further, the Company has no obligation to verify, certify, or manage any transactions between the buyer and the seller.</p>

      <h3>7. Misuse of Platform</h3>
      <p>7.1 You must not misuse our Platform by knowingly introducing viruses, trojans, 	worms, logic bombs or other material that is malicious or technologically harmful. </p>
      <p>7.2 You must not attempt to gain unauthorised access to our Platform, the server on which our Platform is stored, or any server, computer or database connected to our Platform.</p>
      <p>7.3 You must not use Platform in any way that causes, or may cause, damage to the Platform or impairment of the availability or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
      <p>7.4 The Company reserves the right to limit access to specific sections of this Platform, or, at our discretion, to the entire Platform.</p>
      <p>7.5 The Company reserves the right to take legal action, including seeking damages from the NFTs holders or creators, if it is found that the work contains inappropriate content or images, violates laws, or contravenes public morals. This also applies in cases where the work causes damage to the Company&apos;s reputation, image, or other interests.</p>

      <h3>8. Disclaimer</h3>
      <p>The Company reserves the right not to be responsible for any transactions related to the purchase, sale, or transfer of ownership of NFTs held by users, including any derivative works created from those NFTs. Any transactions or agreements between NFTs holders and buyers are considered private agreements between the two parties, in which the Company has no involvement or liability for the outcomes of such agreements or transactions. The Company has no obligation to verify, certify, or manage any sales, transfers, or contracts between NFTs holders and buyers, including the management of benefits, revenue, or any conditions arising from the sale or utilization of NFTs or related derivative works.</p>

      <h3>9. Changes to these Terms of Use</h3>
      <p>The Company reserves the right to amend these Terms of Use at any time without prior notification to users for reason including, but not limited to, complying with relevant laws, governmental policies, regulatory requirements and other obligations of a comparable nature. Any updates will be posted on our Platform. Changes to these Terms of Use shall become effective upon publication on the Platform or on such other date as we may be specified by the Company.</p>

      <h3>10. Entire Agreement</h3>
      <p>These Terms of Use constitute the entire agreement between the Company and the user and supersede and extinguish any and all previous and contemporaneous agreements, promises, assurances, and understandings between the Company and the user, whether written or oral, relating to its subject matter. The user acknowledges and agrees that in entering into these Terms of Use, the user does not rely on, and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not expressly stipulated in these Terms of Use.</p>

      <h3>11. Governing Law</h3>
      <p>These Terms of Use shall be construed in accordance with the laws of Thailand, without reference to its conflict of law provisions, and the obligations, rights and remedies of the parties hereunder shall be determined in accordance with Thai laws. </p>

      <h3>12. Dispute Resolution</h3>
      <p>Please carefully read this Dispute Resolution - Arbitration and Class Action Waiver clause. It significantly impacts your legal rights.</p>

      <h2>Arbitration</h2>

      <p>It provides that all disputes between the Company and the user shall be resolved by binding arbitration. Arbitration is a form of private dispute resolution and replaces the right to go to court. Your acceptance of these Terms of Use constitutes a waiver of your right to litigate claims and all opportunity to be heard by a judge or jury. There is no judge or jury in arbitration, and court review of an arbitration award is limited. The arbitrator shall follow these Terms of Use and can award the same damages and relief as a court. </p>

      <p>Any dispute, controversy, or claim arising out of, relating to, or in connection with these Terms of Use, including any question regarding its existence, validity or termination, or any dispute regarding non-contractual obligations arising out of or relating to it, shall be referred and finally resolved by arbitration administered by the BVI International Arbitration Centre (&ldquo;BVI IAC&rdquo;) under the BVI IAC Arbitration Rules (&ldquo;BVI IAC Rules&rdquo;). The law of the arbitration shall be the laws of Thailand. The place of arbitration shall be Thailand. The number of arbitrators shall be 3 (three) arbitrators. The language to be used in the arbitral proceedings shall be English.</p>

      <p>Any dispute between the Parties that arises from or relates to these Terms of Use, and is not subject to arbitration or cannot be heard in small claims court shall be resolved and filed in the courts of Thailand. In such case you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or legal proceeding arising from these Terms of Use.</p>

      <h2>Class Action Waiver</h2>

      <p>Except as otherwise stipulated in these Terms of Use, to the maximum extent permitted by applicable law, the arbitrators shall not consolidate claims of more than one individual, and shall not otherwise preside over any form of a class or representative proceedings or claims (such as a class action, consolidated action, representative action, or private attorney general action) unless both parties explicitly agree in writing subsequent to the initiation of the arbitration. Neither you, nor any other user of this Platform, may act as a class representative, be a class member, or otherwise participate in a class, consolidated, or representative proceeding.</p>

      <h3>13. Force Majeure</h3>
      <p>The Company shall not be liable for failure or delays in performing its obligations hereunder arising from any cause beyond its control, including but not limited to, acts of god, acts of civil or military authority, fires, strikes, lockouts or labor disputes, epidemics, governmental restrictions, wars, terrorism, riots, earthquakes, storm, typhoons, floods and breakdowns in electronic and computer information and communications systems and internet connection and any other occurrence of event which is beyond our reasonable control.</p>

      <h3>14. Severability</h3>
      <p>If any term, clause, or provision of these Terms of Use is held to be illegal, invalid, void, or unenforceable (in whole or in part), such term, clause, or provision shall be severable from these Terms of Use without affecting the validity or enforceability of any remaining part of that term, clause, or provision, or any other term, clause, or provision in these Terms of Use will remain in full force and effect. Any invalid or unenforceable provisions will be interpreted to affect the intent of the original provisions. In the event that such an interpretation is not feasible, the invalid or unenforceable provision shall be excised from these Terms of Use, but all other provisions hereof shall remain unaffected and in full force and effect.</p>
      
    </div>
  );
}
